main {
  min-height: 85vh;
}
h3 {
  padding: 1rem 0;
}
h1 {
  font-size: 2rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.5rem;
  padding: 0.75rem 0.5rem;
}
.rating span {
  margin: 0.1rem;
}

.d-flex {
  width: 60%;
}

active {
  color: rgb(20, 226, 47) !important;
  background: rgb(19, 204, 59) !important;
}

/* Carousel styling*/
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: absolute;
  top: 0;
}
.mycard-body {
  position: absolute;
  bottom: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
}

.carousel a {
  margin: 0 auto;
}

.bg-carousel-jugomar {
  background-color: #84bc2c;
}

.social {
  right: 200px;
  top: 119vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social li {
  list-style: none;
}

.social li a {
  display: inline-block;
  /*filter:invert(1);*/
  margin-right: 10px;
  transform: scale(0.7);
  transition: 0.5s;
}

.social li a:hover {
  transform: scale(0.7) translateY(-15px);
  filter: invert(1);
}

.badge {
  padding-left: 6px;
  padding-right: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 12px;
  background: #84bc2c;
  color: #fff;
  padding: 0 4px;
  vertical-align: top;
  align-items: center;
  margin-left: -6px;
}
.cart-counter {
  position: relative;
}